<template>
  <div>
    <a-card title="已付款单据">
      <a-row gutter="16">
        <a-col
          :span="24"
          :md="8"
          :xl="6"
          style="max-width: 256px; margin-bottom: 12px"
        >
          <a-input-search
            v-model="searchForm.search"
            placeholder="编号"
            allowClear
            @search="search"
          />
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <template slot="image" slot-scope="value, record">
            <div>
                <img
                  :src="record.payment_images_item[0].file"
                  alt="Preview"
                  @click="showImages(record.payment_images_item)"
                   style="width: 50px; height: 50px; object-fit: cover; cursor: pointer;"
                />
              <!-- 如果数组不存在或为空，也显示文本 -->
            </div>
          </template>

          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button @click="viewPaymentOrders(item)">查看付款详情</a-button>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <payment-details-modal v-model="visible" :items="tableData" />
    <!-- 图片浏览模态框 -->
    <a-modal
      title="Image Gallery"
      :visible="isModalVisible"
      @cancel="handleCancel"
      width="60%"
      footer="null"
    >
      <a-carousel effect="fade" v-model="currentImageIndex">
        <div v-for="(img, index) in images" :key="index">
          <img
            :src="img.file"
            style="width: 100%; height: auto"
            :alt="img.name"
          />
        </div>
      </a-carousel>
    </a-modal>
  </div>
</template>

<script>
import { ScPaymentList } from "@/api/finance";
import NP from "number-precision";

export default {
  name: "PaymentRecord",
  components: {
    PaymentDetailsModal: () => import("./PaymentDetailsModal.vue"),
  },
  data() {
    return {
      //  图片相关
      isModalVisible: false,
      images: [],
      currentImageIndex: 0,
      tableData: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          fixed: "left",
          customRender: (value, item, index) => {
            return index + 1;
          },
          width: 45,
        },
        {
          title: "编号",
          dataIndex: "number",
          fixed: "left",
        },
        {
          title: "单数",
          dataIndex: "index",
          customRender: (text, record) => {
            return record.payment_orders_item.length;
          },
        },
        // {
        //   title: "订单欠款",
        //   dataIndex: "index",
        //   customRender: (text, record) => {
        //     const totalAmountPaid = record.payment_orders_item.reduce(
        //       (total, item) => {
        //         return NP.plus(total, parseFloat(item.amount_paid));
        //       },
        //       0
        //     ); // 初始值设置为0
        //     return totalAmountPaid.toFixed(2);
        //   },
        // },
        {
          title: "其他费用",
          dataIndex: "other_amount",
        },
        {
          title: "总费欠款",
          dataIndex: "total_arrears_amount",
        },
        {
          title: "收款总金额",
          dataIndex: "total_received_amount",
        },
        {
          title: "付款凭证",
          key: "image",
          scopedSlots: { customRender: "image" },
        },
        {
          title: "创建人",
          dataIndex: "creator_name",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          // fixed: 'right',
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
      searchForm: { page: 1, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      visible: false,
      targetItem: {},
      form: {},
    };
  },
  computed: {},
  methods: {
    initialize() {
      var client_id = this.$route.query.clientId;
      this.searchForm["client"] = client_id;
      this.list();
    },
    list() {
      this.loading = true;
      ScPaymentList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${
        sorter.field
      }`;
      this.list();
    },

    showImages(images) {
      this.images = images;
      this.isModalVisible = true;
    },
    handleCancel() {
        this.isModalVisible = false;
      },

    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    viewPaymentOrders(payment_order) {
      // 跳转到查看付款单页面，可以传递客户ID

      var order_info = payment_order.payment_orders_item.map((item) => {
        return item.order_info;
      });
      console.log(111);
      let totalQuantity = 0,
        totalOrder = 0,
        totalAmount = 0,
        totalExpress = 0,
        totalPackage = 0,
        totalOther = 0;
      for (let item of order_info) {
        const salePrice = item.sale_price || 0;

        totalQuantity = NP.plus(totalQuantity, item.use_quantity);
        let amount = NP.times(item.use_quantity, salePrice);
        totalAmount = NP.plus(totalAmount, amount);

        totalExpress = NP.plus(totalExpress, item.express_cost || 0);
        totalPackage = NP.plus(totalPackage, item.package_cost || 0);
        totalOther = NP.plus(totalOther, item.other_amount || 0);
      }
      // 确保折扣不为null且为数字
      // const discount = Number(this.form.discount) || 0;
      // const discountedTotal = NP.times(totalAmount, discount * 0.01);
      totalOrder = NP.plus(totalExpress, totalPackage, totalOther, totalAmount);
      order_info = [
        ...order_info,
        {
          id: "-1",
          isTotal: true,
          name: "",
          totalQuantity: totalQuantity,
          totalAmount,
          totalOrder,
        },
      ];
      this.tableData = order_info;
      this.visible = true;
    },
  },

  mounted() {
    this.initialize();
  },
};
</script>